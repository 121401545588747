// @ts-check
export const resources = {
  translation: {
    "Satellital Patrol": "Satellital Patrol",

    //=== GRAPHQL ===//
    //****** JourneyGroupEnum *****//
    DIARIO: "DIARIO",
    SEMANAL: "SEMANAL",
    QUINCENAL: "QUINCENAL",
    MENSUAL: "MENSUAL",
    IGNITION: "IGNITION",
    PARADAS: "PARADAS",

    //****** src > enums *****//
    Funciones: "Funciones",
    Vehículos: "Vehículos",
    Direcciones: "Direcciones",

    //****** src > enums > functions *****//
    "Asignar N° Guía": "Asignar N° Guía",
    Clientes: "Clientes",
    Conductores: "Conductores",
    Equipos: "Equipos",
    Geocercas: "Geocercas",
    Grupos: "Grupos",
    Líneas: "Líneas",
    Móviles: "Móviles",
    Planificador: "Planificador",
    "Puntos de interés": "Puntos de interés",
    Roles: "Roles",
    Rutas: "Rutas",
    Técnicos: "Técnicos",
    Usuarios: "Usuarios",
    "Reporte de Consolidados": "Reporte de Consolidados",
    "Reporte de Detenciones": "Reporte de Detenciones",
    "Reporte de Eco Tracking": "Reporte de Eco Tracking",
    "Reporte de Eventos": "Reporte de Eventos",
    "Reporte de Geoescalas": "Reporte de Geoescalas",
    "Reporte de Kilometraje de Flota": "Reporte de Kilometraje de Flota",
    "Reporte de OBD2": "Reporte de OBD2",
    "Reporte de Tablero Seguimiento": "Reporte de Tablero Seguimiento",
    "Reporte de Velocidades": "Reporte de Velocidades",
    "Desvios de Ruta": "Desvios de Ruta",
    Mapa: "Mapa",

    //=== routes ===//
    //****** PublicOnlyRoute *****//
    "Ya has ingresado al sistema": "Ya has ingresado al sistema",

    //=== redux > user ===//
    //****** actions *****//
    "Has ingresado satisfactoriamente": "Has ingresado satisfactoriamente",
    "Sesion finalizada con exito": "Sesion finalizada con exito",
    // "Has ingresado satisfactoriamente": "Has ingresado satisfactoriamente",

    //=== Components > AdministrativePanel ===//
    //****** index *****//
    // "Asignar N° Guía": "Asignar N° Guía",
    // Clientes: "Clientes",
    // Conductores: "Conductores",
    // Equipos: "Equipos",
    // Geocercas: "Geocercas",
    // Grupos: "Grupos",
    // Líneas: "Líneas",
    // Móviles: "Móviles",
    // Planificador: "Planificador",
    // "Puntos de interés": "Puntos de interés",
    // Roles: "Roles",
    // Rutas: "Rutas",
    // Técnicos: "Técnicos",
    // Usuarios: "Usuarios",
    "Seguimiento Múltiple": "Seguimiento Múltiple",

    //=== Components > Base ===//

    //****** apiApolloClient *****//

    //****** apiApolloClientSubscription *****//

    //****** CustomFormikSelect *****//
    Seleccionar_: "Seleccionar...",
    "Seleccionar Período": "Seleccionar Período",

    //****** DrawableMap *****//
    "Ver posiciones": "Ver posiciones",
    "Ver puntos de interes": "Ver puntos de interes",

    //****** ExpandablePanel *****//
    Notificaciones: "Notificaciones",

    //****** ExpansionPanel *****//

    //****** FlexRow *****//

    //****** ImageUpload *****//

    //****** Loading *****//

    //****** MainContainer *****//

    //****** ManageNavbar *****//
    // Clientes: "Clientes",
    // Mapa: "Mapa",

    //****** MiniMap *****//
    Cerrar: "Cerrar",
    //****** Modal *****//

    //****** ModalConfirmacion *****//
    Cancelar: "Cancelar",

    //****** ModalPoi *****//
    Nombre: "Nombre",
    "Seleccione Puntos de interes": "Seleccione Puntos de interes",
    "Puntos de Interes": "Puntos de Interes",
    // Cancelar: "Cancelar",
    Guardar: "Guardar",

    //****** Navbar *****//

    //****** NavbarTop *****//
    "Buscar móviles, funciones, geocercas":
      "Buscar móviles, funciones, geocercas...",
    "Ir a Seguimiento Múltiple": "Ir a Seguimiento Múltiple",

    //****** Notification *****//
    "Ha notificado un evento": "Ha notificado un evento",
    //****** PanelForm *****//
    // Guardar: "Guardar",

    //****** PeriodSelectorContainer *****//

    "Ultima hora": "Ultima hora",
    "Ultima 6 horas": "Ultima 6 horas",
    Ayer: "Ayer",
    Hoy: "Hoy",
    "Semana Pasada": "Semana Pasada",
    "Mes Pasado": "Mes Pasado",

    //****** RemoteTable *****//
    base_remote_table_mostrar_resultados:
      "{{from}} a {{to}} de {{size}} Resultados",
    Buscar: "Buscar",

    //****** Structure *****//

    //****** StyledSelect *****//

    //****** Table *****//
    base_table_mostrar_resultados: "{{from}} a {{to}} de {{size}} Resultados",

    //****** TablePanelContent *****//

    //****** ThreeDotMenu *****//

    // navbar.js
    "Mapa Principal": "Mapa Principal",
    "Estado de Flota": "Estado de Flota",
    "Reporte de Recorridos": "Reporte de Recorridos",
    "Conductas de Manejo": "Conductas de Manejo",
    Alarmas: "Alarmas",
    Mantenimiento: "Mantenimiento",
    Administración: "Administración",
    Estadísticas: "Estadísticas",

    // revisions.js
    Seleccionar: "Seleccionar",
    "Revisión técnica": "Revisión técnica",
    Frenos: "Frenos",
    Llantas: "Llantas",
    Batería: "Batería",
    "Cambio de aceiteCambio": "Cambio de aceiteCambio",
    "Tren delantero": "Tren delantero",
    "Correa de transmiciónCorrea": "Correa de transmiciónCorrea",
    Electricidad: "Electricidad",
    "¿Está seguro que desea eliminar la revisión?":
      "¿Está seguro que desea eliminar la revisión?",

    //**** Type of checks ****//
    "Aceite Motor": "Aceite Motor",
    Bateria: "Batería",
    Chapista: "Chapista",
    Correa: "y Distribución Correa y Distribución",
    Cristales: "Cristales",
    Cubiertas: "Cubiertas",
    "Filtro de Aceite": "Filtro de Aceite",
    "Filtro de Aire": "Filtro de Aire",
    "Filtro de Nafta": "Filtro de Nafta",
    Neumaticos: "Neumáticos",
    "Revision General": "Revisión General",
    "Revisión Tecnica": "Revisión Técnica",

    //**** Frequencies *****/
    "1Mes": "1 mes",
    "2Meses": "2 mes",
    "3Meses": "3 mes",
    "12Meses": "12 mes",
    "6Meses": "6 mes",
    "5Años": "5 years",
    Kms: "Millas",
    Meses: "mes",

    //=== Components > CargoTracking ===//
    //****** Form *****//
    "Seguimiento de Cargas": "Seguimiento de Cargas",
    "Número Guía": "Número Guía",
    "Buscar Número de Guía": "Buscar Número de Guía",

    //****** Map *****//

    //****** Page *****//
    "No se encontró el número de guia": "No se encontró el número de guia",
    "Ocurrió un error": "Ocurrió un error",
    "Ingrese el número de guia": "Ingrese el número de guia",

    //=== Components > ChangePasswordModal ===//
    //****** Form *****//
    "Usuario actualizado correctamente": "Usuario actualizado correctamente",
    error: "error",
    Requerido: "Requerido.",
    "Debe tener 6 o más caracteres": "Debe tener 6 o más caracteres",
    "Confirmación Nueva Contraseña": "Confirmación Nueva Contraseña",
    // Cancelar: "Cancelar",
    "Nueva Contraseña": "Nueva Contraseña",
    "Contraseña Actual": "Contraseña Actual",
    // Guardar: "Guardar",

    //****** index *****//
    "Cambiar informacion de perfil": "Cambiar información de perfil",
    "Cambiar Contraseña": "Cambiar Contraseña",

    //=== Components > DrivingBehaviorReport ===//
    //****** AssetTable *****//
    // Nombre: "Nombre",
    Puntaje: "Puntaje",
    "Km Rec": "Millas Rec.",
    Conducción: "Conducción",
    Combustible: "Combustible",
    Eficiencia: "Eficiencia",

    //****** ChartsPanel *****//
    "Promedio Flota": "Promedio Flota",
    "Conductor seleccionado": "Conductor seleccionado",

    //****** ConductionTimeChart *****//
    "Tiempo de Conducción": "Tiempo de Conducción",

    //****** ConsumeEficiencyChart *****//
    "Eficiencia de Consumo": "Eficiencia de Consumo",

    //****** DriverTable *****//
    Promedio: "Promedio",
    Total: "Total",
    // Nombre: "Nombre",
    // "Km Rec": "Km Rec.",
    // Conducción: "Conducción",
    // Combustible: "Combustible",
    // Eficiencia: "Eficiencia",

    //****** ExportMenu *****//

    //****** Filter *****//
    LUNES: "LUNES",
    MARTES: "MARTES",
    MIERCOLES: "MIERCOLES",
    JUEVES: "JUEVES",
    VIERNES: "VIERNES",
    SABADO: "SABADO",
    DOMINGO: "DOMINGO",
    // Nombre: "Nombre",
    Patente: "Patente",
    Interno: "Interno",
    Chofer: "Chofer",
    Tipo: "Tipo",
    // "Nombre": "Nombre", // Duplicated
    Apellido: "Apellido",

    //****** FuelConsumedChart *****//
    "Combustible Consumido": "Combustible Consumido",

    //****** Index *****//
    // Conductores: "Conductores",
    // Vehículos: "Vehículos",

    //****** InfractionsChart *****//

    //****** KmsRecChart *****//
    "Kilómetros Recorridos": "Millas Recorridos",

    //****** ScoreChart *****//
    "Puntaje Total": "Puntaje Total",
    "Puntaje Flota": "Puntaje Flota",
    "Kms Recorridos Flota": "Millas Recorridas Flota",
    "Tiempo de conducción Flota": "Tiempo de conducción Flota",
    "Tiempo de conduccion": "Tiempo de conducción",
    "Combustible consumido": "Combustible consumido",
    "Combustible consumido Flota": "Combustible consumido Flota",
    "Eficiencia Flota": "Eficiencia Flota",

    //****** ScoreGaugeChart *****//

    //=== Components > EstadoFlotaPanel ===//
    //****** AssetState *****//

    //****** FlotaItem *****//
    components_estadoflotapanel_floatitem_point_vel: "{{pointVel}} km/h",

    //****** FlotaState *****//
    "Total Flota": "Total Flota",
    "Ignition ON": "Ignition ON",
    "Ignition OFF - En movimiento": "Ignition OFF - En movimiento",
    "Ignition OFF - Detenido": "Ignition OFF - Detenido",
    "Sin configuración de ignición": "Sin configuración de ignición",
    "Sin transmisión por x minutos o más":
      "Sin transmisión por {{minutos}} minutos o más",
    Persona: "Persona",

    //****** index *****//

    //=== Components > EstadoFlotaPanel > ExportPanel ===//
    //****** DownloadButtons *****//
    "Exportar PDF": "Exportar PDF",
    "Exportar Excel": "Exportar Excel",

    //****** FlotaStateExportPanel *****//
    ReporteEstadoFlota: "ReporteEstadoFlota",
    "Error al intentar descargar archivo":
      "Error al intentar descargar archivo",

    error_al_intentar_guardar_el_archivo_descargado_formato_no_soportado:
      "Error al intentar guardar el archivo descargado. Formato no soportado.",
    //=== Components > JourneyReportPanel ===//
    //****** index *****//
    "Seleccione un movil": "Seleccione un movil",
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Agrupar por": "Agrupar por...",
    // Buscar: "Buscar",
    No_hay_resultados: "No hay resultados",
    EXCEL: "EXCEL",

    //****** JourneyAsset *****//

    //****** JourneyDetail *****//
    "Tiempo Recorrido:": "Tiempo Recorrido:",
    "Kms Recorridos:": "Millas Recorridas:",
    "Horómetro:": "Horómetro:",
    // "Tiempo Ralentí:": "Tiempo Ralentí:",
    km: "millas",

    //****** JourneySummary *****//
    Móvil: "Móvil",
    "Velocidad Máxima": "Velocidad Máxima",
    "Tiempo recorrido": "Tiempo recorrido",
    "Kilometros recorridos": "Kilómetros recorridos",
    Kilometraje: "Kilometraje",
    "Tiempo ralenti": "Tiempo ralenti",

    //****** ModalJourneyPosition *****//
    "pagina previa": "Página previa",
    "próxima página": "Próxima página",
    "primer página": "Primer página",
    "última página": "Última página",
    Conductor: "Conductor",
    Fecha: "Fecha",
    Evento: "Evento",
    Latitud: "Latitud",
    Longitud: "Longitud",
    Dirección: "Dirección",
    Curso: "Curso",
    Velocidad: "Velocidad",
    Posiciones: "Posiciones",
    // EXCEL: "EXCEL",
    PDF: "PDF",
    KML: "KML",
    // Cerrar: "Cerrar",
    modal_journey_position_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //=== Components > Login ===//
    //****** Login *****//

    //****** LoginForm *****//
    Ingresar: "Ingresar",
    Usuario: "Usuario",
    Contraseña: "Contraseña",

    //=== Components > Map ===//
    //****** AssetsInAreaSearch *****//
    "Radio de busqueda": "Radio de búsqueda",
    Distancia: "Distancia",
    "Sin informacion disponible": "Sin información disponible",
    "Seleccionar movil": "Seleccionar móvil",
    "Busqueda de moviles cercanos": "Búsqueda de móviles cercanos",
    "%assetsCount% moviles en esta area de busqueda":
      "{{assetsCount}} móviles en esta área",
    "Quitar busqueda en area": "Quitar búsqueda en área",
    "Mostrar busqueda en area": "Mostrar búsqueda en área",
    //****** BaseLayersControl *****//

    //****** BaseMap *****//
    "Geocercas agregadas correctamente": "Geocercas agregadas correctamente",
    "El cliente seleccionado no tiene geocercas":
      "El cliente seleccionado no tiene geocercas",
    "Se ocultaron las geocercas": "Se ocultaron las geocercas",
    "Quitar Dejar Rastro": "Quitar Dejar Rastro",
    "Habilitar Dejar Rastro": "Habilitar Dejar Rastro",
    "Ocultar geocercas": "Ocultar geocercas",
    "Ver geocercas": "Ver geocercas",
    Remover: "Remover",
    "Quitar Tiempo": "Quitar Tiempo",
    "Mostrar Tiempo": "Mostrar Tiempo",
    "Deshabilitado Por favor, aumente el nivel de zoom":
      "Deshabilitado - Por favor, aumente el nivel de zoom.",
    //****** BaseMapOld *****//

    //****** BaseMapV3 *****//

    //****** GenericPopup *****//
    // Velocidad: "Velocidad",
    Tiempo: "Tiempo",
    Rumbo: "Rumbo",

    //****** MarkerPopup *****//

    //****** MarkersGroup *****//

    //****** MarkersJourneys *****//

    //****** NavigationPopup *****//
    "La unidad se agregó correctamente para seguimiento múltiple":
      "La unidad se agregó correctamente para seguimiento múltiple.",
    "Ya existen 4 unidades en el seguimiento múltiple_ Por favor_ quite alguna para poder continuar agregando_":
      "Ya existen 4 unidades en el seguimiento múltiple. Por favor, quite alguna para poder continuar agregando.",
    "Quitar de seguimiento múltiple": "Quitar de seguimiento múltiple",
    "La unidad se quitó correctamente del seguimiento múltiple":
      "La unidad se quitó correctamente del seguimiento múltiple.",
    "Agregar a seguimiento múltiple": "Agregar a seguimiento múltiple",
    // Velocidad: "Velocidad",
    // Tiempo: "Tiempo",
    // Rumbo: "Rumbo",
    "Street View": "Street View",
    "Fecha GPS:": "Fecha GPS:",
    Fecha_GPS: "Fecha GPS",
    "Seguir en nueva ventana": "Seguir en nueva ventana",

    "On Time": "A tiempo",
    Early: "Temprano",
    Late: "Tarde",
    "Off Route": "Fuera de ruta",
    "Skipped Stops": "Paradas omitidas",
    Unknown: "Desconocido",
    "Ruta Activa": "Ruta Activa",

    //****** NavigationRouting *****//

    //****** NavigationRoutingV3 *****//

    //****** react-leaflet-markercluster *****//

    //****** ShowStatus *****//

    //=== Components > Map > JourneyComponents  ===//
    //****** ChartContainer *****//
    // Velocidad: "Velocidad",
    // Kilometraje: "Kilometraje",
    "Dirección:": "Dirección:",
    "Fecha:": "Fecha:",
    "km/h": "millas/h",
    kmh: "millas/h",

    //****** CustomTrackPlayer *****//

    //****** FuelChart *****//

    //****** KilometrajeChart *****//

    //****** VelocityChart *****//

    //=== Components > Map > MarkerInfo  ===//
    //****** DaySteps *****//

    //****** FuelRefill *****//
    Combustibles: "Combustibles",

    //****** GeneralInfo *****//
    // "Street View": "Street View",
    "Copiar Lat/Lon": "Copiar Lat/Lon",
    Orientación: "Orientación",
    // Velocidad: "Velocidad",
    Odómetro: "Odómetro",
    "Odómetro software": "Odómetro software",
    Horómetro: "Horómetro",
    // Combustible: "Combustible",
    "Última transmisión": "Última transmisión",
    "Tiempo detención": "Tiempo detención",
    "Ver detalles": "Ver detalles",
    "Copiado al portapapeles!": "Copiado al portapapeles!",
    VelMax: "Vel. Max",
    "Voltaje Externo": "Voltaje Externo",
    "Voltaje Batería": "Voltaje Batería",
    "GNSS Stat": "GNSS Stat",

    //****** GeneralInfoExtra *****//
    "Información general": "Información general",
    "Nombre vehículo": "Nombre vehículo",
    "Num Interno": "Num Interno",
    Cliente: "Cliente",
    "Nombre del conductor": "Nombre del conductor",
    "Datos del Equipo": "Datos del Equipo",
    IMEI: "IMEI",
    "Tipo de Equipo": "Tipo de Equipo",
    Modelo: "Modelo",
    "Nro de Serie": "Nro de Serie",
    "Nro de Teléfono": "Nro de Teléfono",
    "Versión del script": "Versión del script",
    "Fecha de Instalación": "Fecha de Instalación",
    "Fecha de Alta Línea": "Fecha de Alta Línea",

    //****** InfoContent *****//

    //****** InfoContentExtra *****//

    //****** Journey *****//
    "Este marcador no posee información de viajes recientes":
      "Este marcador no posee información de viajes recientes",
    "Ubicación inicial": "Ubicación inicial",
    "Ubicación final": "Ubicación final",
    "Duración de trabajo": "Duración de trabajo",
    "Duración de la parada": "Duración de la parada",
    // Kilometraje: "Kilometraje",
    "Kilometraje (Cuenta kilómetros virtual)":
      "Kilometraje (Cuenta kilómetros virtual)",
    Recorridos: "Recorridos",
    "No se han encontrado resultados para el rango de busqueda establecido":
      "No se han encontrado resultados para el rango de busqueda establecido.",

    //****** Notifications *****//
    Eventos: "Eventos",
    Alertas: "Alertas",
    "Notificacion enviada": "Notificacion enviada",
    "Condiciones de inicio de evento": "Condiciones de inicio de evento",
    Locaclización: "Localización",
    "Equipo Origen": "Equipo Origen",
    "Equipos de destino": "Equipos de destino",
    //****** Revisions *****//
    // "Este marcador no posee revisiones": "Este marcador no posee revisiones",
    "Proximas revisiones": "Proximas revisiones",

    //****** RevisionsExtra *****//
    // Seleccionar: "Seleccionar",
    Correo: "Correo",

    //****** StepList *****//
    Inicio: "Inicio",
    Parada: "Parada",
    "Duración del tramo": "Duración del tramo",
    "Distancia recorrida": "Distancia recorrida",
    // "Duración de la parada": "Duración de la parada",

    //****** SubHeader *****//

    //=== Components > Menu  ===//
    //****** CustomerSelect *****//
    // Clientes: "Clientes",

    //****** GroupRCTree *****//

    //****** GroupsButton *****//
    // Grupos: "Grupos",

    //****** GroupTree *****//

    //****** JourneyByCoordenates *****//

    //****** Menu *****//
    Principal: "Principal",
    Messages: "Messages",

    //****** MenuItem *****//

    //****** NotificationsButton *****//
    "Seleccionar todo": "Seleccionar todo",
    "Asentir Alarmas": "Asentir Alarmas",
    "VER TODOS": "VER TODOS",
    "Seleccionar Todas": "Seleccionar Todas",
    Sonido: "Sonido",
    "No hay notificaciones": "No hay notificaciones.",

    //****** UsersButton *****//
    // "Cambiar Contraseña": "Cambiar Contraseña",
    "Seleccionar idioma": "Seleccionar idioma",
    "Cerrar sesión": "Cerrar sesión",
    "User profile avatar": "User profile avatar",
    // Usuario: "Usuario", // Duplicated

    //****** GroupsTree *****//
    Buscar_: "Buscar...",

    //=== Components > MultipleTracking  ===//
    //****** MultipleTracking *****//
    "Aún no ha agregado ningún móvil al Seguimiento Múltiple":
      "Aún no ha agregado ningún móvil al Seguimiento Múltiple.",

    //=== Components > NewLateralBar  ===//
    //****** IconsContainer *****//

    //****** index *****//

    //****** Panel *****//
    // Notificaciones: "Notificaciones",
    // Mantenimiento: "Mantenimiento",
    // Administración: "Administración",
    // "Reporte de Recorridos": "Reporte de Recorridos",
    // "Estado de Flota": "Estado de Flota",
    // Estadísticas: "Estadísticas",
    // "Conductas de Manejo": "Conductas de Manejo",

    //****** PanelAux *****//

    //=== Components > NotificationPanel  ===//
    //****** Filter *****//
    "Filtrar notificaciones": "Filtrar notificaciones",
    // "Asentir Alarmas": "Asentir Alarmas",
    "Tipo de notificación": "Tipo de notificación...",
    "Tipo de Alarma": "Tipo de Alarma...",
    "Seleccione Evento": "Seleccione Evento...",
    "Borrar filtros": "Borrar filtros",
    Activado: "Activado",
    Desactivado: "Desactivado",
    Hardware: "Hardware",
    Software: "Software",
    "Sin transmisión": "Sin transmisión",
    Alarma: "Alarma",
    Todas: "Todas",

    //****** index *****//
    "Get Support": "Soporte",
    //****** ListNotifications *****//
    Cargando: "Cargando...",
    Fin: "Fin",
    "No se encontraron datos": "No se encontraron datos",

    //****** NotificationItem *****//
    notification_item_posicion_alarma:
      "Posición de Alarma - {{source}} - Cliente: {{client}} - Evento: {{event}}",
    Asentir: "Asentir",
    "Ver en mapa": "Ver en mapa",
    Vel: "Vel.",

    //=== Components > OBD2Modal ===//
    //****** OBD2DisplayGrid *****//
    //****** OBD2DisplayItem *****//
    //****** OBD2Hook *****//
    //****** OBD2InformationDisplay *****//
    //****** OBD2Modal *****//
    OBD2: "OBD2",
    datos_OBD2: "Datos OBD2",
    //****** OBD2ModalWindow *****//
    obd2_modal_window_ndtc: "Cod. Fallas",
    obd2_modal_window_celv: "Carga Motor [%]",
    obd2_modal_window_ect: "Temp. antic. [ºC]",
    obd2_modal_window_fp: "Presión Comb. [kPa]",
    obd2_modal_window_ft: "Ajuste comb. [%]",
    obd2_modal_window_imap: "Presión admi. [%]",
    obd2_modal_window_rpm: "RPM",
    obd2_modal_window_speed: "Velocidad OBD2 [millas/h]",
    obd2_modal_window_ta: "Movimiento [º]",
    obd2_modal_window_iat: "Temp. Aire motor [ºC]",
    obd2_modal_window_tp: "Posición acel. [%]",
    obd2_modal_window_tign: "Tiemp. Motor Om [s]  ",
    obd2_modal_window_dtmil: "Dist. Mil on [Millas]",
    obd2_modal_window_panic: "Pánico",
    obd2_modal_window_ignition: "Ignición ",
    obd2_modal_window_movement: "Movimiento",
    obd2_modal_window_bi: "Batería Interna [V]",
    obd2_modal_window_ac: "Alimentación [V]",
    obd2_modal_window_gsm: "Señal GSM",
    obd2_modal_window_velocidad: "Velocidad [Millas/h]",
    obd2_modal_window_kilometrostotales: "Millas Totales [Millas]",
    obd2_modal_window_coordenadas: "Coordenadas",
    obd2_modal_window_fgps: "Fecha GPS",
    "No hay informacion OBD2 disponible":
      "No hay información OBD2/CAN BUS disponible.",
    "OBD2-CANBUS": "OBD2 / CAN BUS",
    //****** OBD2ModalToggleButton *****//

    //=== Components > ReaxiumDeviceSyncButton ===//
    //****** index *****//
    "El proceso de sincronizacion de equipos Reaxium iniciara en breve":
      "El proceso de sincronizacion de equipos Reaxium iniciara en breve",
    "El proceso de sincronizacion de equipos Reaxium ya se encuentra en ejecucion":
      "El proceso de sincronizacion de equipos Reaxium ya se encuentra en ejecucion",
    "Iniciar sincronizacion Reaxium": "Iniciar sincronizacion Reaxium",
    //=== Components > RevisionsPanel  ===//
    //****** FilterRevision *****//
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Nueva Revisión": "Nueva Revisión",
    Revision: "Revision",
    "Tipo de Revisión": "Tipo de Revisión",
    "Nuevo aviso de revisión": "Nuevo aviso de revisión",

    //****** FilterRevisionNotice *****//
    // "Nombre": "Nombre", // duplicado
    // "Patente": "Patente", // duplicado
    // "Interno": "Interno", // duplicado
    // "Chofer": "Chofer", // duplicado
    // "Tipo": "Tipo", // duplicado

    //****** FormRevision *****//
    // "Nombre": "Nombre", // duplicado
    // "Patente": "Patente", // duplicado
    // "Interno": "Interno", // duplicado
    // "Chofer": "Chofer", // duplicado
    // "Tipo": "Tipo", // duplicado
    "Revision eliminada correctamente": "Revision eliminada correctamente",
    "Revisión creado correctamente": "Revisión creada correctamente",
    "Revisión actualizada correctamente": "Revisión actualizada correctamente",
    // "Móviles": "Móviles", // duplicado
    // Guardar: "Guardar",
    Eliminar: "Eliminar",
    IMPORTANTE: "IMPORTANTE",

    //****** FormRevisionNotice *****//
    "Aviso de revision creado correctamente":
      "Aviso de revision creado correctamente",
    "Aviso de revision actualizado correctamente":
      "Aviso de revision actualizado correctamente",
    "Aviso de revision eliminado correctamente":
      "Aviso de revision eliminado correctamente",
    // Nombre: "Nombre",
    // Apellido: "Apellido",
    // Usuario: "Usuario",
    Email: "Email",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // Móviles: "Móviles",
    "Seleccione el motivo por el cual quiere generar una alerta periodica":
      "Seleccione el motivo por el cual quiere generar una alerta periodica",
    "Fecha pasada o futura a partir de la cual comienza el conteo para el primer aviso":
      "Fecha pasada o futura a partir de la cual comienza el conteo para el primer aviso",
    // Seleccionar: "Seleccionar",
    // Fecha: "Fecha",
    "Cada cuantos kms o meses se desea el aviso":
      "Cada cuántas millas o meses se desea el aviso",
    Frecuencia: "Frecuencia",
    "Porcentaje de alerta": "Porcentaje de alerta",
    "Porcentaje de kms o tiempo restante para la revision":
      "Porcentaje de millas o tiempo restante para la revisión",
    "Personas no registradas en el sistema que recibiran el aviso":
      "Personas no registradas en el sistema que recibiran el aviso",
    "Agregar email no registrado": "Agregar email no registrado",
    // Guardar: "Guardar",
    "¿Está seguro que desea eliminar el aviso de revisión?":
      "¿Está seguro que desea eliminar el aviso de revisión?",
    // Eliminar: "Eliminar",

    //****** index *****//
    "Próximas revisiones": "Próximas revisiones",
    Revisiones: "Revisiones",

    //****** Itemrevision *****//
    "Editar Revisión": "Editar Revisión",
    // Revision: "Revision",

    //****** ItemrevisionNotice *****//

    //****** ListRevision *****//

    //****** ListRevisionNotice *****//

    //=== Components > SelectLanguageModal  ===//
    //****** Form *****//
    "Idioma actualizado correctamente": "Idioma actualizado correctamente.",
    // Guardar: "Guardar",
    // Cancelar: "Cancelar",
    "Seleccione idioma": "Seleccione idioma",

    //****** index *****//
    "Cambiar Idioma": "Cambiar Idioma",

    //=== Components > Selectors  ===//
    //****** AssetSelector *****//
    // Nombre: "Nombre",
    // Patente: "Patente",
    // Interno: "Interno",
    // Chofer: "Chofer",
    // Tipo: "Tipo",
    // "pagina previa": "Página previa",
    // "próxima página": "Próxima página",
    // "primer página": "Primer página",
    // "última página": "Última página",
    "Seleccionar Moviles": "Seleccionar Moviles",
    // Cancelar: "Cancelar",
    // Seleccionar: "Seleccionar",
    asset_selector_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** DateSelector *****//
    "Última hora": "Última hora",
    "Última 6 horas": "Última 6 horas",
    // Ayer: "Ayer",
    // Hoy: "Hoy",
    "Semana pasada": "Semana pasada",
    "Mes pasado": "Mes pasado",
    Personalizado: "Personalizado",
    // LUNES: "LUNES",
    // MARTES: "MARTES",
    // MIERCOLES: "MIERCOLES",
    // JUEVES: "JUEVES",
    // VIERNES: "VIERNES",
    // SABADO: "SABADO",
    // DOMINGO: "DOMINGO",
    Lunes: "Lunes",
    Martes: "Martes",
    Miercoles: "Miercoles",
    Jueves: "Jueves",
    Viernes: "Viernes",
    Sabado: "Sabado",
    Domingo: "Domingo",
    Confirmar: "Confirmar",
    // Cancelar: "Cancelar",
    "Fechas personalizadas": "Fechas personalizadas",
    //****** GenericSelector *****//
    "Seleccionar Todos": "Seleccionar Todos",
    // Cancelar: "Cancelar",
    "Seleccionar rango de busqueda": "Seleccionar rango de búsqueda",
    buscar_por_titulo: "Buscar {{title}}...",

    //****** LineSelector *****//
    "Buscar líneas": "Buscar líneas...",

    //=== Components > StatisticsPanel  ===//
    //****** index *****//
    Consolidados: "Consolidados",
    "Consumo de Combustible": "Consumo de Combustible",
    Detenciones: "Detenciones",
    "Eco Tracking": "Eco Tracking",
    // Eventos: "Eventos",
    Geoescalas: "Geoescalas",
    "Kilometraje de Flota": "Distancia de Flota Recorrida",
    "Móviles en Geocercas": "Móviles en Geocercas",
    "Móviles en Geocercas - Histórico": "Móviles en Geocercas - Histórico",
    // OBD2: "OBD2",
    "Tablero Seguimiento": "Tablero Seguimiento",
    Temperaturas: "Temperaturas",
    Velocidades: "Velocidades",

    //=== Pages > Manage > Assets ===//
    //****** AsignacionEquipo *****//
    Identificador: "Identificador",
    "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    "Nro Serie": "Nro Serie",
    "Nro Telefono": "Nro Telefono",
    Imei: "Imei",
    "Fecha Alta": "Fecha Alta",
    Código: "Código",
    // Nombre: "Nombre",
    // Apellido: "Apellido",
    // Email: "Email",
    Teléfono: "Teléfono",
    "Teléfono Móvil": "Teléfono Móvil",
    "Asignacion actualizada correctamente":
      "Asignacion actualizada correctamente",
    Equipo: "Equipo",
    Tecnicos: "Tecnicos",
    // Cancelar: "Cancelar",
    // Guardar: "Guardar",
    "Ver Historial": "Ver Historial",
    "Fecha instalación": "Fecha instalación",

    //****** AssetForm *****//
    // Nombre: "Nombre",
    CUIT: "CUIT",
    Telefonos: "Telefonos",
    Celular: "Celular",
    Domicilio: "Domicilio",
    // Apellido: "Apellido",
    // Teléfono: "Teléfono",
    "Teléfono Empresa": "Teléfono Empresa",
    "Tipo de Móvil": "Tipo de Móvil",
    Placa: "Placa",
    "Número de Interno": "Número de Interno",
    Marca: "Marca",
    Color: "Color",
    Proveedor: "Proveedor",
    // Modelo: "Modelo",
    Año: "Año",
    Notas: "Notas",
    "Electrovalvula de corte": "Electroválvula de corte",
    "Corte de Combustible": "Corte de Combustible",
    "Velocidad Maxima": "Velocidad Máxima",
    // Seleccionar: "Seleccionar",
    "Tensión Principal": "Tensión Principal",
    // "Velocidad Máxima": "Velocidad Máxima",
    "Odómetro Real Inicial": "Odómetro Real Inicial",
    "Capacidad Tanque": "Capacidad Tanque",
    "Capacidad Máxima Tanque": "Capacidad Máxima Tanque",
    "Horómetro Real Inicial": "Horómetro Real Inicial",
    // Cancelar: "Cancelar",
    Crear: "Crear",
    Actualizar: "Actualizar",
    Foto: "Foto",
    "Habilitar Alertas": "Habilitar alertas",
    "Fecha Instalacion Corte de Combustible":
      "Fecha Instalación Corte de Combustible",
    "Fecha Instalacion Electroválvula de Corte":
      "Fecha Instalación Electroválvula de Corte",
    "Actualizar Móvil": "Actualizar Móvil",
    "Crear Móvil": "Crear Móvil",
    "Soporta OBD2": "Soporta OBD2",

    //****** AssetForm - asset types *****//
    "4X4": "4X4",
    BUS: "BUS",
    CUSTER: "CUSTER",
    MAN: "MAN",
    MINIVAN: "MINIVAN",
    MOTO: "MOTO",
    SEDAN: "SEDAN",
    SPORT: "SPORT",
    STATION: "STATION",
    TRACTOR: "TRACTOR",
    TRUCK: "TRUCK",
    VAN: "VAN",
    BALANCÍN: "BALANCÍN",
    UTILITARIO: "UTILITARIO",
    PERSONA: "PERSONA",
    CAMIÓN: "CAMIÓN",
    GRÚA: "GRÚA",
    "CAJA PORTÁTIL": "CAJA PORTÁTIL",
    CARRO: "CARRO",
    REGADORA: "REGADORA",
    "PTO FIJO": "PTO FIJO",
    RETROEXCAVADORA: "RETROEXCAVADORA",
    "CAMIÓN PETROLERO": "CAMIÓN PETROLERO",
    CASILLA: "CASILLA",
    MAQUINA: "MAQUINA",

    //****** EcoTrackingForm *****//
    "Consumo entre 0 y 50 millas/h (l/100millas)":
      "Consumo entre 0 y 50 millas/h (l/100millas)",
    "Consumo entre 51 y 100 millas/h (l/100millas)":
      "Consumo entre 51 y 100 millas/h (l/100millas)",
    "Consumo mayor a 100 millas/h (l/100millas)":
      "Consumo mayor a 100 millas/h (l/100millas)",
    "Consumo ralentí (l/hora)": "Consumo ralentí (l/hora)",
    "Emisión CO2 (gramos/litro)": "Emisión CO2 (gramos/litro)",
    // Cancelar: "Cancelar",
    // Eliminar: "Eliminar",

    //******* MaxSpeed Form ******//
    "Verificar velocidad inválida": "Verificar velocidad inválida",

    //****** index *****//
    "Móvil creado correctamente": "Móvil creado correctamente",
    "Equipo asignado correctamente": "Equipo asignado correctamente",
    "Móvil actualizado correctamente": "Móvil actualizado correctamente",
    "Tablero de Seguimiento actualizado correctamente":
      "Tablero de Seguimiento actualizado correctamente",
    "Móvil eliminado correctamente": "Móvil eliminado correctamente",
    // "Ocurrió un error": "Ocurrió un error",
    "Información de EcoTracking creada correctamente":
      "Información de EcoTracking creada correctamente",
    "Información de EcoTracking actualizada correctamente":
      "Información de EcoTracking actualizada correctamente",
    "Información de EcoTracking eliminada correctamente":
      "Información de EcoTracking eliminada correctamente",
    // "Ocurrió un error" // duplicado

    //****** List *****//
    manage_assets_list_eliminar_movil:
      "Está seguro de eliminar el móvil {{name}}",
    "Tipo de Activo": "Tipo de Activo",
    // Nombre: "Nombre",
    // Placa: "Placa",
    "Nro Interno": "Nro. Interno",
    "Equipo ID": "Equipo ID",
    // IMEI: "IMEI",
    // Cliente: "Cliente",
    Estado: "Estado",
    manage_assets_list_mostrar_resultados:
      "Mostrando {from} de {to} de {size} Resultados",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    // IMPORTANTE: "IMPORTANTE",
    "Asignacion de Equipos": "Asignacion de Equipos",
    "Tablero de Seguimiento": "Tablero de Seguimiento",
    // "Velocidad Máxima": "Velocidad Máxima",
    "+ Móvil": "+ Móvil",

    //****** MaxSpeed *****//
    "Este campo debe ser numérico": "Este campo debe ser numérico",
    "Este campo bebe ser mayor que cero": "Este campo bebe ser mayor que cero",
    "Invalidar velocidad superior a": "Invalidar velocidad superior a",
    // Actualizar: "Actualizar",
    // Cancelar: "Cancelar",

    //****** ModalhistorialAD *****//
    // Equipo: "Equipo",
    // "Tipo de Equipo": "Tipo de Equipo",
    Linea: "Linea",
    Técnico: "Técnico",
    Operación: "Operación",
    // Fecha: "Fecha",
    "Historial de Asignaciones": "Historial de Asignaciones",
    // Cerrar: "Cerrar",

    //****** Revisiones *****//

    //****** TrackingDashboard *****//
    "Numero de Operación": "Número de Operación",
    "Dias de Detención Maximos": "Días de Detención Máximos",
    "Promedio Teórico de Kilómetros diarios":
      "Promedio Teórico de Kilómetros diarios",
    "Promedio Horómetro Diario Teórico": "Promedio Horómetro Diario Teórico",
    // "Este campo bebe ser mayor que cero": "Este campo bebe ser mayor que cero",
    // "Este campo debe ser numérico": "Este campo debe ser numérico",

    //=== Pages > Manage > Client ===//
    //****** ClientForm *****//
    // Requerido: "Requerido",
    "Email invalido": "Email invalido",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    Teléfonos: "Teléfonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    Mail: "Mail",
    "Nivel de Zoom (%)": "Nivel de Zoom (%)",
    // Cancelar: "Cancelar",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    Suspendido: "Suspendido",
    "Se requiere un email si las alarmas de email automaticas estan activadas":
      "Se requiere un email si las alarmas de email automáticas están activadas",

    //****** index *****//
    "Cliente creado correctamente": "Cliente creado correctamente",
    // "Ocurrió un error": "Ocurrió un error",
    "Cliente actualizado correctamente": "Cliente actualizado correctamente",
    "Cliente eliminado correctamente": "Cliente eliminado correctamente",

    //****** List *****//
    pages_manage_clients_eliminar_cliente:
      "¿Está seguro que desea eliminar el cliente {{name}} ?",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    pages_manage_clients_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",
    "Página previa": "Página previa",
    // "Próxima página": "Próxima página",
    // "Primera página": "Primera página",
    // "Última página": "Última página",
    // IMPORTANTE: "IMPORTANTE",
    "Actualizar Cliente": "Actualizar Cliente",
    "Crear Cliente": "Crear Cliente",
    "+ Cliente": "+ Cliente",

    //=== Pages > Manage > Devices ===//
    //****** Form *****//
    // Identificador: "Identificador",
    // "Tipo de Equipo": "Tipo de Equipo",
    // IMEI: "IMEI",
    // Modelo: "Modelo",
    // "Nro de Serie": "Nro. de Serie",
    "Versión Firmware": "Versión Firmware",
    "Versión Script": "Versión Script",
    "Clave Expiración": "Clave Expiración",
    "Factor de Conversión Temp": "Factor de Conversión Temp.",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    // Cancelar: "Cancelar",
    "Can Bus": "Can Bus",
    "Actualizar Equipo": "Actualizar Equipo",
    "Crear Equipo": "Create Device",
    //****** index *****//
    "Equipo creado correctamente": "Equipo creado correctamente",
    "Ocurrió un error_ Intente nuevamente":
      "Ocurrió un error. Intente nuevamente",
    "Equipo actualizado correctamente": "Equipo actualizado correctamente",
    "Equipo eliminado correctamente": "Equipo eliminado correctamente",
    "Líneas asociadas correctamente": "Líneas asociadas correctamente",
    // Equipos: "Equipos",
    pages_manage_devices_index_seguro_desea_eliminar_equipo:
      "Está seguro de eliminar el equipo {{deviceIdentifier}}",
    "El dispositivo no puede ser eliminado porque se encuentra asignado a un asset":
      "El dispositivo no puede ser eliminado porque se encuentra asignado a un móvil.",

    //****** List *****//
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    // "Confirmar eliminación": "Confirmar eliminación",
    // IMPORTANTE: "IMPORTANTE",
    "+ Equipo": "+ Equipo",

    //****** ModalClonacionEquipos *****//
    "Equipo clonado correctamente": "Equipo clonado correctamente",
    // Evento: "Evento",
    Valor: "Valor",
    "Es pedido posición": "Es pedido posición",
    "Es reporte automático": "Es reporte automático",
    "Es evento de entrada": "Es evento de entrada",
    "Es evento de salida": "Es evento de salida",
    "Es ignition on": "Es ignition on",
    "Es ignition off": "Es ignition off",
    "Es puerta abierta": "Es puerta abierta",
    "Es evento software": "Es evento software",
    // Equipo: "Equipo",
    "Seleccione uno o mas equipos de destino":
      "Seleccione uno o mas equipos de destino",
    "Seleccione uno o mas eventos a clonar":
      "Seleccione uno o mas eventos a clonar",
    "Origen de la clonación": "Origen de la clonación",
    "Destino de la clonación": "Destino de la clonación",
    // Cancelar: "Cancelar",
    // Guardar: "Guardar",
    "Clonación de Equipos": "Clonación de Equipos",
    "Clonar toda la configuración": "Clonar toda la configuración",
    "Clonar solo algunos eventos": "Clonar solo algunos eventos",
    "Puede escribir varios equipos separador por ;":
      "Puede escribir varios equipos separador por ;",

    //****** ModalConfiguracionEvento *****//
    "Eventos clonados correctamente": "Eventos clonados correctamente",
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // Evento: "Evento",
    // Valor: "Valor",
    "Es alarma": "Es alarma",
    "Es alarma movimiento": "Es alarma movimiento",
    // "Es pedido posición": "Es pedido posición",
    // "Es reporte automático": "Es reporte automático",
    // "Es evento de entrada": "Es evento de entrada",
    // "Es evento de salida": "Es evento de salida",
    // "Es ignition on": "Es ignition on",
    // "Es ignition off": "Es ignition off",
    // "Es puerta abierta": "Es puerta abierta",
    // "Es evento software": "Es evento software",
    "Clonar Eventos": "Clonar Eventos",
    "Nuevo Evento": "Nuevo Evento",
    Clonar: "Clonar",
    // Evento: "Evento",
    // Valor: "Valor",
    manage_device_modal_configuracion_evento_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** ModalLineas *****//
    // Líneas: "Líneas",

    //=== Pages > Manage > Drivers ===//
    //****** Form *****//
    IButton: "IButton",
    "Compañia Teléfono": "Compañia Teléfono",
    "Compañia Celular": "Compañia Celular",
    Observaciones: "Observaciones",

    //****** index *****//
    "Driver creado correctamente": "Driver creado correctamente",
    "Driver actualizado correctamente": "Driver actualizado correctamente",
    "Driver eliminado correctamente": "Driver eliminado correctamente",

    //****** List *****//
    "Eliminar un Conductor": "Eliminar un Conductor",
    pages_manage_drivers_esta_seguro_eliminar_conductor:
      "Está seguro de eliminar el Conductor {{fullName}}",
    Si: "Si",
    No: "No",
    // Cliente: "Cliente",
    // "Compañia Teléfono": "Compañia Teléfono",
    // Celular: "Celular",
    // "Compañia Teléfono": "Compañia Teléfono",
    // Suspendido: "Suspendido",
    pages_manage_drivers_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",
    "Actualizar Conductor": "Actualizar Conductor",
    "Crear Conductor": "Crear Conductor",
    // Conductores: "Conductores",
    "+ Conductor": "+ Conductor",

    //=== Pages > Manage > Geofences ===//
    //****** Form *****//
    // Cliente: "Cliente",
    "Nombre Geocerca": "Nombre Geocerca",
    "Mostrar en mapa": "Mostrar en mapa",
    "Tiene alarmas E/S": "Tiene alarmas E/S",
    "Tiene máxima velocidad": "Tiene máxima velocidad",
    "Máxima Velocidad": "Máxima Velocidad",
    "Tipo de Geocerca": "Tipo de Geocerca",
    "Por favor dibuje el poligono correspondiente a la geocerca":
      "Por favor, dibuje el poligono correspondiente a la geocerca.",
    //****** index *****//
    "Geocerca creada correctamente": "Geocerca creada correctamente",
    "Geocerca actualizada correctamente": "Geocerca actualizada correctamente",
    "Geocerca eliminada correctamente": "Geocerca eliminada correctamente",
    // Geocercas: "Geocercas",

    //****** List *****//
    "Eliminar Geocerca": "Eliminar Geocerca",
    pages_manage_geofences_esta_seguro_eliminar_geocerca:
      "Está seguro de eliminar la geocerca {{name}} ?",
    Editar: "Editar",
    "Ver geocerca": "Ver geocerca",
    // "Mostrar en mapa": "Mostrar en mapa",
    "Actualizar Geocerca": "Actualizar Geocerca",
    "Crear Geocerca": "Crear Geocerca",
    "+ Geocerca": "+ Geocerca",

    //****** Map *****//

    //=== Pages > Manage > Groups ===//
    //****** AssetPanel *****//
    // Móviles: "Móviles",
    pages_manage_groups_moviles_pertenecientes_al_grupo:
      "Móviles pertenecientes al grupo: {{groupName}}",
    "Busqueda de móviles": "Busqueda de móviles",
    "Quitar movil": "Quitar movil",
    "Quitar todos los móviles": "Quitar todos los móviles",
    "Asignar Flota": "Assignar Flota",

    //****** GroupPanel *****//
    // Grupos: "Grupos",
    pages_manage_groups_grupos_pertenecientes_al_cliente:
      "Grupos pertenecientes al cliente: {{customerName}}",
    "Guardar cambios": "Guardar cambios",
    "Crear grupo": "Crear grupo",
    "Nuevo grupo": "Nuevo grupo",
    "Eliminar grupo": "Eliminar grupo",
    "Asociar otra flota": "Asociar otra flota",
    "Crear subgrupo": "Create subgroup",
    "Cambiar nombre": "Change name",
    "Asignar usuarios": "Assign users",
    "Asignar moviles": "Assign buses",

    //****** index *****//
    "Grupo creado correctamente": "Grupo creado correctamente",
    "Móviles asignados correctamente": "Móviles asignados correctamente",
    "Usuarios asignados correctamente": "Usuarios asignados correctamente",
    "Usuarios quitados correctamente": "Usuarios quitados correctamente",
    "Móviles desasignados correctamente": "Móviles desasignados correctamente",
    "No se puede quitar móviles del grupo raiz":
      "No se puede quitar móviles del grupo raiz",
    "No se puede asignar móviles al grupo raiz":
      "No se puede asignar móviles al grupo raiz",
    "Grupo eliminado correctamente": "Grupo eliminado correctamente",
    "No se puede eliminar el grupo raiz": "No se puede eliminar el grupo raiz",
    "Grupo actualizado correctamente": "Grupo actualizado correctamente",
    "Debe seleccionar un cliente": "Debe seleccionar un cliente",
    // Grupos: "Grupos",

    //****** ModalAssets *****//
    // Nombre: "Nombre",
    // Placa: "Placa",
    "Búsqueda de Móviles": "Búsqueda de Móviles",
    // Cancelar: "Cancelar",
    // Seleccionar: "Seleccionar",

    //****** ModalAssociateFleet *****//
    "Asociar otras flotas": "Asociar otras flotas",
    // Grupos: "Grupos",
    // "Guardar cambios": "Guardar cambios",

    //****** ModalSubUser *****//
    // Requerido: "Requerido",
    // "Email invalido": "Email invalido",
    "Usuario creado correctamente": "Usuario creado correctamente",
    "Editar Usuario": "Editar Usuario",
    "Nuevo Usuario": "Nuevo Usuario",
    "Las contraseñas no coinciden": "Las contraseñas no coinciden",
    "Seleccione un grupo": "Seleccione un grupo",
    "Nueva contraseña": "Nueva contraseña",
    "Confirmación de contraseña": "Confirmación de contraseña",
    "Correo Electrónico": "Correo Electrónico",
    // Usuario: "Usuario",
    Perfil: "Perfil",

    //****** ModalUsers *****//
    // Usuario: "Usuario",
    "Búsqueda de Usuarios": "Búsqueda de Usuarios",

    //****** UserPanel *****//
    // Usuarios: "Usuarios",
    pages_manage_groups_usuarios_pertenecientes_al_grupo:
      "Usuarios pertenecientes al grupo: {{groupName}}",
    "Busqueda de Usuarios": "Busqueda de Usuarios",
    "Quitar usuario": "Quitar usuario",
    "Quitar todos los usuarios": "Quitar todos los usuarios",
    "Crear sub-usuario": "Crear sub-usuario",
    "Eliminar usuario": "Eliminar usuario",
    "Editar usuario": "Editar usuario",

    //=== Pages > Manage > GuideNumber ===//
    //****** Form *****//
    // Interno: "Interno",
    "Número de Guía": "Número de Guía",
    // Actualizar: "Actualizar",
    Asignar: "Asignar",

    //****** index *****//
    "Número de guía asignado correctamente":
      "Número de guía asignado correctamente",
    "Número de guía desasignado correctamente":
      "Número de guía desasignado correctamente",
    // "Ocurrió un error": "Ocurrió un error",

    //****** List *****//
    "Desasignar Número de Guía": "Desasignar Número de Guía",
    pages_manage_guidenumber_desasignar_numero_guia:
      "Está seguro de desasignar el número de guía {{guideNumber}}",
    Desasignar: "Desasignar",
    // "Número de Guía": "Número de Guía",
    pages_manage_guidenumber_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",
    // "Página previa": "Página previa",
    // "Próxima página": "Próxima página",
    // "Primera página": "Primera página",
    // "Última página": "Última página",
    "Actualizar Número de Guía": "Actualizar Número de Guía",
    "Asignar Número de Guía": "Asignar Número de Guía",
    "Asignar números de guía": "Asignar números de guía",
    "+ Número de Guía": "+ Número de Guía",

    //=== Pages > Manage > Lines ===//
    //****** index *****//
    "Línea creado correctamente": "Línea creado correctamente",
    "Línea actualizado correctamente": "Línea actualizado correctamente",
    "Línea eliminada correctamente": "Línea eliminada correctamente",
    // "Ocurrió un error": "Ocurrió un error",

    //****** LineForm *****//
    "Email no válido": "Email no válido",
    // Identificador: "Identificador",
    // "Tipo Equipo": "Tipo Equipo",
    // Modelo: "Modelo",
    // "Nro Serie": "Nro Serie",
    // "Nro Telefono": "Nro Telefono",
    // Imei: "Imei",
    // "Fecha Alta": "Fecha Alta",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    // Cliente: "Cliente",
    Número: "Número",
    SIM: "SIM",
    "Tipo Comunicación": "Tipo Comunicación",
    // Email: "Email",
    // Actualizar: "Actualizar",
    // Crear: "Crear",

    //****** List *****//
    "Eliminar Línea": "Eliminar Línea",
    pages_manage_linea_seguro_eliminar_linea:
      "Está seguro de eliminar la línea {{name}}",
    // Editar: "Editar",
    // Eliminar: "Eliminar",
    // Número: "Número",
    "Número SIM": "Número SIM",
    // Cliente: "Cliente",
    // "Tipo Comunicación": "Tipo Comunicación",
    // Email: "Email",
    // "Equipo ID": "Equipo ID",
    "Actualizar Línea": "Actualizar Línea",
    "Crear Línea": "Crear Línea",
    // Líneas: "Líneas",
    "+ Línea": "+ Línea",
    pages_manage_linea_mostrar_resultado:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //=== Pages > Manage > POIs ===//
    //****** Form *****//
    // Requerido: "Requerido",
    // Nombre: "Nombre",
    // CUIT: "CUIT",
    // Telefonos: "Telefonos",
    // Celular: "Celular",
    // Domicilio: "Domicilio",
    // Cliente: "Cliente",
    // Nombre: "Nombre",
    // Latitud: "Latitud",
    Longitude: "Longitude",
    Radio: "Radio",
    Categoría: "Categoría",
    // Actualizar: "Actualizar",
    // Crear: "Crear",
    // Cancelar: "Cancelar",

    //****** index *****//
    // "Geocerca creada correctamente": "Geocerca creada correctamente",
    // "Geocerca actualizada correctamente": "Geocerca actualizada correctamente",
    // "Geocerca eliminada correctamente": "Geocerca eliminada correctamente",
    "Puntos de Interés": "Puntos de Interés",

    //****** List *****//
    "Eliminar Punto de Interés": "Eliminar Punto de Interés",
    pages_manage_pois_seguro_eliminar_poi:
      "Está seguro de eliminar el punto de interés {{name}}",
    "Ver Puntos de Interés": "Ver Puntos de Interés",
    // Latitud: "Latitud",
    // Longitude: "Longitude",
    // Radio: "Radio",
    // Categoría: "Categoría",
    // "pagina previa": "pagina previa",
    // "próxima página": "próxima página",
    // "primer página": "primer página",
    // "última página": "última página",
    "Confirmar eliminación": "Confirmar eliminación",
    // IMPORTANTE: "IMPORTANTE",
    "Ver Punto de Interés": "Ver Punto de Interés",
    "Actualizar Punto de Interés": "Actualizar Punto de Interés",
    "Crear Punto de Interés": "Crear Punto de Interés",
    "+ Punto de Interés": "+ Punto de Interés",

    //****** Map *****//

    //=== Pages > Manage > ReportPlanning ===//
    "Reporte de detenciones": "Reporte de detenciones",
    "Reporte de eventos": "Reporte de eventos",
    "Reporte de kilometraje de flota": "Reporte de kilometraje de flota",
    "Reporte de geoescalas": "Reporte de geoescalas",
    "Reporte de recorridos": "Reporte de recorridos",
    "Reporte de velocidades": "Reporte de velocidades",
    "Reporte de Tablero de Seguimiento": "Reporte de Tablero de Seguimiento",
    "Listado de posiciones": "Listado de posiciones",
    "Reporte Consolidado": "Reporte Consolidado",
    "Reporte Eco Tracking": "Reporte Eco Tracking",
    "Reporte OBD2": "Reporte OBD2",
    "Reporte Desvio de Paradas": "Reporte Desvio de Paradas",

    //****** Report types *****//

    DETENCIONES: "DETENCIONES",
    EVENTOS: "EVENTOS",
    KILOMETRAJEFLOTA: "KILOMETRAJEFLOTA",
    GEOESCALAS: "GEOESCALAS",
    RECORRIDOS: "RECORRIDOS",
    VELOCIDADES: "VELOCIDADES",
    TABLEROSEGUIMIENTO: "TABLEROSEGUIMIENTO",
    POSICIONES: "POSICIONES",
    CONSOLIDADO: "CONSOLIDADO",
    ECOTRACKING: "ECOTRACKING",
    // OBD2: "OBD2",
    STOPSDETOUR: "DESVIODEPARADA",

    //****** Form *****//
    pages_manage_reportingplanning_actualizar_por_nombre: "Actualizar {{name}}",
    "Crear Reporte Planificado": "Crear Reporte Planificado",

    //****** FormHeader *****//
    // Planificador: "Planificador",
    "Tipo de Reporte": "Tipo de Reporte",
    Formato: "Formato",
    Habilitado: "Habilitado",
    // Cliente: "Cliente",
    Excel: "Excel",
    pages_manage_reportingplanning_reporte_creado_por:
      "Planificación creada por {{creadaPor}}",
    "Desvio de Paradas": "Stops Detour",
    //****** FormNotification *****//
    "Notificar via email a": "Notificar via email a:",
    "Otras direcciones": "Otras direcciones",
    "Otras direcciones Cco": "Otras direcciones Cco:",

    //****** FormProgramation *****//
    Horas: "Horas",
    // Inicio: "Inicio",
    // Fin: "Fin",
    // Frecuencia: "Frecuencia",
    Ejecución: "Ejecución",
    // Habilitado: "Habilitado",
    "Nueva Programación": "Nueva Programación",
    "Editar Programación": "Editar Programación",
    Habilitada: "Habilitada",
    "Sucede una vez a las %at%": "Sucede una vez, a las {{at}}",
    "Sucede cada %every% horas desde %from% hasta %to%":
      "Sucede cada {{every}} horas, desde {{from}} hasta {{to}}",
    "Sucede una vez a las": "Sucede una vez a las",
    "Sucede cada": "Sucede cada",
    // Inicio: "Inicio",
    // Fin: "Fin",
    // Frecuencia: "Frecuencia",
    Diaria: "Diaria",
    Diario: "Diario",
    Semanal: "Semanal",
    Mensual: "Mensual",
    Cada: "Cada",
    dias: "días",
    "Todos los dias de la semana": "Todos los días de la semana",
    "Repetir cada": "Repetir cada",
    "semanas el": "semanas, el/los día/s:",
    "El día": "El día",
    "de cada": "de cada",
    El: "El",
    // "de cada": "de cada",
    "Intervalo de repetición": "Intervalo de repetición",
    "Comenzar el": "Comenzar el",
    "Finalizar el": "Finalizar el",
    Programaciones: "Programaciones",
    "+ Programación": "+ Programación",

    //****** FormReportFilters *****//
    // "Última hora": "Última hora",
    "Últimas seis horas": "Últimas seis horas",
    // Hoy: "Hoy",
    // Ayer: "Ayer",
    // "Semana pasada": "Semana pasada",
    // "Mes pasado": "Mes pasado",
    // "Mostrar en mapa": "Mostrar en mapa",
    // "Velocidad Máxima": "Velocidad Máxima",
    Filtros: "Filtros",
    "Todos los móviles activos": "Todos los móviles activos",
    // Seleccionar_: "Seleccionar...",
    "Velocidad Hasta": "Velocidad Hasta",
    // Conductores: "Conductores",
    // Geocercas: "Geocercas",
    "Tiempo Permanencia minimo [s]": "Tiempo Permanencia minimo [s]",

    //****** index *****//
    "Reporte planificado creado correctamente":
      "Reporte planificado creado correctamente",
    "Reporte planificado actualizado correctamente":
      "Reporte planificado actualizado correctamente",
    "Reporte planificado eliminado correctamente":
      "Reporte planificado eliminado correctamente",
    "Planificador de Reportes": "Planificador de Reportes",

    //****** List *****//
    pages_manage_reportingplanning_seguro_eliminar_planificacion:
      "Está seguro de eliminar la planificacion {{name}}",
    Plan: "Plan",
    "Creado por": "Creado por",
    Reporte: "Reporte",
    // Formato: "Formato",
    // Habilitado: "Habilitado",
    // "Confirmar eliminación": "Confirmar eliminación",
    "+ Reporte Planificado": "+ Reporte Planificado",
    "Debe ingresar al menos un destinatario":
      "Debe ingresar al menos un destinatario",

    //=== Pages > Manage > Roles ===//
    //****** index *****//
    "Permisos asignados correctamente": "Permisos asignados correctamente",
    "Rol creado correctamente": "Rol creado correctamente",
    "Rol actualizado correctamente": "Rol actualizado correctamente",
    "Rol eliminado correctamente": "Rol eliminado correctamente",
    // "Ocurrió un error": "Ocurrió un error",

    //****** List *****//
    "Eliminar Rol": "Eliminar Rol",
    "Asignar Permisos": "Asignar Permisos",
    Rol: "Rol",
    // Categoría: "Categoría",
    "Actualizar Rol": "Actualizar Rol",
    "Crear Rol": "Crear Rol",
    // Roles: "Roles",
    "+ Rol": "+ Rol",
    pages_manage_roles_seguro_eliminar_rol:
      "Está seguro de eliminar el rol {{name}}",
    pages_manage_roles_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** ModalAsignarPermisos *****//
    pages_manage_roleseleccionado: "Permisos {{name}}",

    //****** RoleForm *****//
    // Rol: "Rol",
    "Administrador Plataforma": "Administrador Plataforma",
    "Administrador Cliente": "Administrador Cliente",
    Operador: "Operador",

    //=== Pages > Manage > Routes ===//
    //****** Form *****//
    // "Mostrar en mapa": "Mostrar en mapa",
    // "Velocidad Máxima": "Velocidad Máxima",
    Descripción: "Descripción",
    "Activar Alarma de desvio": "Activar Alarma de desvio",
    Desvío: "Desvío",
    "Activar Alarma de velocidad": "Activar Alarma de velocidad",
    // "Velocidad Máxima": "Velocidad Máxima",
    // Geocercas: "Geocercas",

    //****** GeofenceMap *****//

    //****** index *****//
    "Ruta creada correctamente": "Ruta creada correctamente",
    "Ruta actualizada correctamente": "Ruta actualizada correctamente",
    "Ruta eliminada correctamente": "Ruta eliminada correctamente",
    // Rutas: "Rutas",
    "Actualizar Ruta": "Actualizar Ruta",
    "Crear Ruta": "Crear Ruta",
    "+ Ruta": "+ Ruta",

    //****** List *****//
    "Ver Ruta": "Ver Ruta",
    "Aviso Desvío": "Aviso Desvío",
    "Mts Desvío": "Mts. Desvío",
    "Aviso Velocidad": "Aviso Velocidad",
    "Máx Velocidad [Km/h]": "Máx. Velocidad [Millas/h]",
    pages_manage_routes_seguro_eliminar_ruta:
      "Está seguro de eliminar la ruta {{name}}",

    //=== Pages > Manage > Routes ===//
    //****** index *****//
    "Técnico creado correctamente": "Técnico creado correctamente",
    "Técnico actualizado correctamente": "Técnico actualizado correctamente",
    "Técnico eliminado correctamente": "Técnico eliminado correctamente",

    //****** List *****//
    "Eliminar Ténico": "Eliminar Ténico",
    // Código: "Código",
    "Actualizar Técnico": "Actualizar Técnico",
    "Crear Técnico": "Crear Técnico",
    // Técnicos: "Técnicos",
    "+ Técnico": "+ Técnico",
    pages_manage_technicians_seguro_eliminar_tecnico:
      "Está seguro de eliminar el técnico {{name}}",
    pages_manage_technicians_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** TechnicianForm *****//
    // Código: "Código",

    //=== Pages > Manage > Users ===//
    //****** index *****//
    // "Usuario creado correctamente": "Usuario creado correctamente",
    // "Usuario actualizado correctamente": "Usuario actualizado correctamente",
    "Usuario eliminado correctamente": "Usuario eliminado correctamente",
    pages_manage_users_seguro_eliminar_user:
      "Está seguro de eliminar el usuario {{name}}",
    // Usuarios: "Usuarios",

    //****** List *****//
    // "Eliminar Línea": "Eliminar Línea",
    // Roles: "Roles",
    Activo: "Activo",
    Inactivo: "Inactivo",
    "Actualizar Usuario": "Actualizar Usuario",
    "Crear Usuario": "Crear Usuario",
    "+ Usuario": "+ Usuario",
    pages_manage_users_seguro_eliminar_linea:
      "Está seguro de eliminar la línea {{name}}",
    // pages_manage_technicians_mostrar_resultados:
    // "Mostrando {{from}} de {{to}} de {{size}} Resultados",

    //****** ModalAsignarRoles *****//
    "Roles asignados correctamente": "Roles asignados correctamente",
    Categoria: "Categoria",

    //****** UserForm *****//
    // Contraseña: "Contraseña",
    "Teléfono 1": "Teléfono 1",
    "Teléfono 2": "Teléfono 2",
    // "Teléfono Móvil": "Teléfono Móvil",
    "Imagen de Perfil": "Imagen de Perfil",
    "Tiempo de Session": "Tiempo de Session",
    // Activo: "Activo",
    "Habilitar Google Maps": "Habilitar Google Maps",

    //=== Pages > Manage > Statistics ===//
    //****** userFilterValidation *****//

    //=== Pages > Manage > Statistics > ConsolidateReport ===//
    //****** Filter *****//
    // Interno: "Interno",
    Fechas: "Fechas",
    Resumido: "Resumido",
    "Debe seleccionar al menos un móvil": "Debe seleccionar al menos un móvil.",
    "Generar Reporte": "Generar Reporte",

    //****** index *****//
    "No se encontraron datos para su consulta":
      "No se encontraron datos para su consulta.",
    // Consolidados: "Consolidados",

    //=== Pages > Manage > Statistics > DetentionReport ===//
    //****** Filters *****//
    "Estado Móvil": "Estado Móvil",
    "Tiempo Mínimo (m)": "Tiempo Mínimo (m)",
    " Generar Reporte": " Generar Reporte",
    // "Debe seleccionar al menos un móvil": "Debe seleccionar al menos un móvil.",
    "exportar excel": "exportar excel",
    "exportar pdf": "exportar pdf",
    // KML: "KML",
    "exportar kml": "exportar kml",

    //****** index *****//
    // "No se encontraron datos para su consulta":
    //   "No se encontraron datos para su consulta.",
    // Estadísticas: "Estadísticas",
    // Detenciones: "Detenciones",

    //****** ReportResult *****//
    Lugar: "Lugar",
    // Estado: "Estado",
    "Duración detención": "Duración detención",
    "Ver punto": "Ver punto",
    Detención: "Detención",

    //=== Pages > Manage > Statistics > EcoTrackingReport ===//
    //****** Filter *****//

    //****** index *****//
    // "No se encontraron datos para su consulta":
    //   "No se encontraron datos para su consulta.",
    EcoTracking: "EcoTracking",

    //****** ReportResult *****//
    "Kms Recorridos": "Millas Recorridas",
    "Consumo Baja": "Consumo Baja",
    "Consumo Media": "Consumo Media",
    "Consumo Alta": "Consumo Alta",
    "Consumo Ralenti": "Consumo Ralenti",
    cO2: "cO2",
    "Litros Total [l]": "Litros Total [l]",
    "Emision CO2 - NOx Total[g]": "Emision CO2 - NOx Total[g]",

    //=== Pages > Manage > Statistics > EventReport ===//
    //****** Filter *****//
    // Eventos: "Eventos",
    // "Debe seleccionar al menos un móvil": "Debe seleccionar al menos un móvil.",

    //****** index *****//

    //****** ReportResult *****//
    Desde: "Hasta",
    Hasta: "Hasta",
    "Tiempo Recorrido": "Tiempo Recorrido",
    "Eventos Totales": "Eventos Totales",
    // KML: "KML",
    // Fecha: "Fecha",
    // "Tiempo Recorrido": "Tiempo Recorrido",
    // "Kms Recorridos": "Kms Recorridos",
    Ver: "Ver",

    //=== Pages > Manage > Statistics > FleetKmReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Kilometraje de Flota": "Kilometraje de Flota",

    //****** ReportResult *****//
    "Tipo Movil": "Tipo Movil",
    "Nombre Móvil": "Nombre Móvil",
    // "Kms Recorridos": "Kms Recorridos",
    "Vel_ Maxima Promedio": "Vel. Maxima Promedio",
    // "Velocidad Máxima": "Velocidad Máxima",
    "Ubicación Velocidad Máxima": "Ubicación Velocidad Máxima",

    //=== Pages > Manage > Statistics > FuelConsumptionReport ===//
    //****** Filter *****//

    //****** index *****//
    "Reporte de Eficiencia de Consumo Combustible":
      "Reporte de Eficiencia de Consumo Combustible",

    //****** ReportResult *****//
    "Kms Recorrido": "Millas Recorrido",
    "Ralentí [Hs]": "Ralentí [Hs]",
    "Ratio Consumo": "Ratio Consumo",

    //=== Pages > Manage > Statistics > GeoScaleReport ===//
    //=== Pages > Statistics > RouteDetourReport ===//
    "Debe seleccionar una ruta": "Debe seleccionar una ruta",
    "Debe seleccionar un móvil": "Debe seleccionar un móvil",
    "Debe seleccionar una fecha de inicio de ruta":
      "Debe seleccionar una fecha de inicio de ruta.",
    "Debe seleccionar una fecha de fin de ruta":
      "Debe seleccionar una fecha de fin de ruta.",
    "Desvio de ruta": "Desvio de ruta",
    "Desvios de ruta": "Desvio de ruta",
    "Inicio de ruta": "Inicio de ruta",
    "Fin de ruta": "Fin de ruta",
    "Vehiculo en ruta": "Vehiculo en ruta",
    "Inicio trayecto": "Inicio trayecto",
    "Fin trayecto": "Fin trayecto",
    "Inicio desvio": "Inicio desvio",
    "Fin desvio": "Fin desvio",
    Prom: "Prom.",

    //=== Pages > Statistics > StopDetourReport ===//
    "Horario esperado de llegada": "Horario esperado de llegada",
    "Horario real de llegada": "Horario real de llegada",
    "Horario real de salida": "Horario real de salida",
    "Desvios de Parada": "Desvíos de parada",
    "Tiempo en parada": "Tiempo en parada",
    aprox: "aprox",

    //=== Pages > Statistics > StopHistoryReport ===//
    "Histórico de Paradas": "Histórico de Paradas",
    Orden: "Orden",

    //****** Filter *****//

    //****** index *****//

    //****** ReportResult *****//
    Entrada: "Fecha Entrada",
    Salida: "Fecha Salida",
    Duración: "Duración",
    Geocerca: "Geocerca",
    "Nombre móvil": "Nombre Móvil",

    //=== Pages > Manage > Statistics > KmReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Kilometraje de Flota": "Kilometraje de Flota",

    //****** ReportResult *****//
    // "Tipo de Móvil": "Tipo de Móvil",
    // "Kms Recorrido": "Kms Recorrido",
    "Velocidad Promedio": "Velocidad Promedio",
    // "Ubicación Velocidad Máxima": "Ubicación Velocidad Máxima",
    // Fecha: "Fecha",
    // "Tiempo Recorrido": "Tiempo Recorrido",
    // "Kms Recorridos": "Kms Recorridos",
    // Eventos: "Eventos",
    // Conductor: "Conductor",
    // Lugar: "Lugar",
    // Ver: "Ver",

    //=== Pages > Manage > Statistics > OBD2 ===//
    //****** Chart *****//

    //****** Filter *****//
    "Variable OBD2": "Variable OBD2",
    // "Reporte OBD2": "Reporte OBD2",
    "Debe seleccionar al menos una variable OBD2":
      "Debe seleccionar al menos una variable OBD2",

    //****** index *****//
    Odometro: "Odometro",
    "Cantidad de Fallas": "Cantidad de Fallas",
    Cantidad: "Cantidad",
    "Carga del motor [%]": "Carga del motor [%]",
    "Temperatura del anticongelante [C°]":
      "Temperatura del anticongelante [C°]",
    "Presion de Combustible [kPa]": "Presion de Combustible [kPa]",
    "Ajuste de combustible [%]": "Ajuste de combustible [%]",
    "Presión del colector de admision [%]":
      "Presión del colector de admision [%]",
    RPM: "RPM",
    "Velocidad OBD2 [km/h]": "Velocidad OBD2 [millas/h]",
    "Movimiento [°]": "Movimiento [°]",
    "Temperatura Aire motor [C°]": "Temperatura Aire motor [C°]",
    "Posición del acelerador [%]": "Posición del acelerador [%]",
    "Tiempo desde encendido de motor [s]":
      "Tiempo desde encendido de motor [s]",
    "Distancia recorrida con MIL on [Millas]":
      "Distancia recorrida con MIL on [Millas]",
    Panico: "Panico",
    Ignición: "Ignición",
    Movimiento: "Movimiento",
    "Bateria Interna [V]": "Bateria Interna [V]",
    "Alimentación [V]": "Alimentación [V]",
    "Señal GSM": "Señal GSM",

    //****** ReportResult *****//

    //=== Pages > Manage > Statistics > TrackingBoardReport ===//
    //****** Filter *****//

    //****** index *****//
    // "Tablero de Seguimiento": "Tablero de Seguimiento",

    //****** ReportResult *****//
    "Nro Operación": "Nro Operación",
    "Dias Detención Max": "Días Detención Max",
    "Dias Detención Actual": "Días Detención Actual",
    "Promedio Teorico Km Diarios": "Promedio Teorico Millas Diarios",
    "Promedio Real Km Diarios": "Promedio Real Millas Diarios",
    "Promedio Horometro Diario Teorico (H)":
      "Promedio Horometro Diario Teorico (H)",
    "Promedio Horometro Diario Real (H)": "Promedio Horometro Diario Real (H)",
    "Zona Operaciones Evaluada": "Zona Operaciones Evaluada",
    "Zona Operaciones Real": "Zona Operaciones Real",

    //=== Pages > Manage > Statistics > VelocityReport ===//
    //****** Filter *****//
    // Conductores: "Conductores",
    "Velocidad Desde": "Velocidad Desde",
    // "Velocidad Hasta": "Velocidad Hasta",

    //****** index *****//
    // Velocidad: "Velocidad",

    //****** ReportResult *****//
    "Velocidad Máxima Movil": "Velocidad Máxima Móvil",
    Ubicación: "Ubicación",
    Detalles: "Detalles",
    pages_manage_statistics_velocityreport_mostrar_resultados:
      "Mostrando {{from}} de {{to}} de {{size}} Resultados",
    pages_manage_statistics_velocityreport_velocidad:
      "Velocidad {{vel}} [Millas/h], {{assetName}}",

    //=== Pages ===//
    //****** Forbidden *****//
    "Acceso Denegado": "Acceso Denegado",
    "No tiene permisos para acceder a esta página":
      "No tiene permisos para acceder a esta página",
    "Volver a página principal": "Volver a página principal",

    //****** LoginPage *****//

    //****** UserPage *****//

    //****** Geofences ***** */
    "Importar Geocercas (KML)": "Importar Geocercas (KML)",
    "Importar Geocercas": "Importar Geocercas",
    Importar: "Importar",
    "Seleccione KML": "Seleccione KML",
    "Archivo KML importado correctamente":
      "Archivo KML importado correctamente",
    "Cliente invalido":
      "Cliente invalido. Debe seleccionar exactamente un cliente para poder realizar esta operacion.",

    // ** RUMBO **/
    N: "N",
    NE: "NE",
    E: "E",
    SE: "SE",
    S: "S",
    SO: "SO",
    O: "O",
    NO: "NO",

    // ** File names ** //
    ReporteRecorridos: "ReporteRecorridos",
    ListadoPosiciones: "ListadoPosiciones",
    ReporteEventos: "ReporteEventos",
    ReporteVelocidad: "ReporteVelocidad",
    ReporteConsolidado: "ReporteConsolidado",
    ReporteDetencion: "ReporteDetencion",
    ReporteEcoTracking: "ReporteEcoTracking",
    ReporteKmFlota: "ReporteKmFlota",
    ReporteConsumoCombustible: "ReporteConsumoCombustible",
    ReporteGeoescalas: "ReporteGeoescalas",
    ReporteKms: "ReporteKms",
    ReporteOBD2: "ReporteOBD2",
    ReporteDesviosDeParada: "ReporteDesviosDeParada",
    ReporteTableroSeguimiento: "ReporteTableroSeguimiento",

    // ** Leaflet mesasure distance units ** //
    primaryLengthUnit: "meters",
    secondaryLengthUnit: "kilometers",
    primaryAreaUnit: "sqmeters",
    secondaryAreaUnit: "acres",

    // ** Permissions ** //
    "Mostrar filtro de conductores en reportes de estadistica":
      "Mostrar filtro de conductores en reportes de estadística",
    "Mostrar cuenta de personas en el panel de estado de flota":
      "Mostrar cuenta de personas en el panel de estado de flota",
    "Crear, modificar y eliminar Clientes":
      "Crear, modificar y eliminar Clientes",
    "Crear, modificar y eliminar Lineas": "Crear, modificar y eliminar Lineas",
    "Administrar N° Guia": "Administrar N° Guia",
    "Crear, modificar y eliminar Equipos":
      "Crear, modificar y eliminar Equipos",
    "Crear, modificar y eliminar Geocercas":
      "Crear, modificar y eliminar Geocercas",
    "Crear, modificar y eliminar Grupos": "Crear, modificar y eliminar Grupos",
    "Crear, modificar y eliminar Moviles":
      "Crear, modificar y eliminar Moviles",
    "Planificar Reportes": "Schedule Reports",
    "Administrar Usuarios": "Administrar Usuarios",
    "Crear, modificar y eliminar Puntos de interes":
      "Crear, modificar y eliminar Puntos de interes",
    "Crear, modificar y eliminar Roles": "Crear, modificar y eliminar Roles",
    "Crear, modificar y eliminar Rutas": "Crear, modificar y eliminar Rutas",
    "Crear, modificar y eliminar Tecnicos":
      "Crear, modificar y eliminar Tecnicos",
    "Crear, modificar y eliminar Usuarios":
      "Crear, modificar y eliminar Usuarios",
    "Reporte de Kilometraje de flota": "Reporte de Kilometraje de flota",
    "Reporte móviles en geocerca": "Reporte móviles en geocerca",
    "Reporte móviles en geocercas Historico":
      "Reporte móviles en geocercas Historico",
    "Reporte Tablero de Seguimiento": "Reporte Tablero de Seguimiento",
    "Reporte de Temperaturas": "Reporte de Temperaturas",
    "Reporte de Velocidad": "Reporte de Velocidad",
    "Ver notificaciones y asentir alarmas":
      "Ver notificaciones y asentir alarmas",
    "Reporte de conductas de manejo": "Reporte de conductas de manejo",
    "Mantenimiento de móviles": "Mantenimiento de móviles",
    "Asentir alarmas": "Asentir alarmas",
    "Marcar notificaciones como leidas": "Marcar notificaciones como leidas",
    "Asignar equipo a un móvil": "Asignar equipo a un móvil",
    "Asignar roles a usuarios": "Asignar roles a usuarios",
    "Asignar flota de otro cliente a un grupo":
      "Asignar flota de otro cliente a un grupo",
    "Asignar lineas a un equipo": "Asignar lineas a un equipo",
    "Crear, modificar y eliminar conductores":
      "Crear, modificar y eliminar conductores",
    "Columnas extras reporte Eco Tracking":
      "Columnas extras reporte Eco Tracking",
    "Reporte de Consumo de Combustible": "Reporte de Consumo de Combustible",
    "Reporte de Desvio de Ruta": "Reporte de Desvio de Ruta",
    "Reporte de Desvio de Parada": "Reporte de Desvio de Parada",
    "Reporte de Comparación de Paradas": "Reporte de Comparación de Paradas",
    "Importar geofences desde archivo KML":
      "Importar geofences desde archivo KML",
    "Iniciar la sincronizacion de los dispositivos de Reaxium":
      "Iniciar la sincronizacion de los dispositivos de Reaxium",
    "Configurar notificaciones por mail": "Configurar notificaciones por mail",
    // ** Permissions/Roles Categories **//
    General: "General",
    Acciones: "Actions",

    //** Others **/
    Todos: "Todos",
    "Configuracion Evento": "Configuración Evento",
    "Asignar Equipo": "Asignar Equipo",
    Grupo: "Grupo",
    Movil: "Móvil",
    "Fecha de Evento": "Fecha de Evento",
    "Hora de Llegada Prevista": "Hora de Llegada Prevista",
    "errores-continuar": "Por favor, corrija todos los errores para continuar.",
    /* Configuracion de notificaciones */
    ConfNotifications: "Configuración de Notificaciones",
    ConfNotifUpdated: "Configuración de notificación actualizada",
    ConfNotifCreated: "Configuración de notificación creada",
    ConfNotifDeleted: "Configuración de notificación eliminada",
    DeleteConfNotificationTitle: "Eliminar configuración de notificación",
    DeleteConfNotificationMessage:
      "¿Está seguro que desea eliminar la configuración {{name}} ?",
    CrearConfiguracion: "Crear configuración",
    "+ Configuración": "+ Configuración",
    ActualizarConfiguracion: "Actualizar configuración",
    ExcesoVelocidad: "Exceso de velocidad",
    ExcesoVelocidadEnGeocerca: "Exceso de velocidad en geocerca",
    EventosESGeocerca: "Eventos de E/S en geocerca",
    EventosDeRuta: "Eventos de ruta",
    EventosHardware: "Eventos de Hardware",
    EventosSoftware: "Eventos de Software",
    //ItemRevisionNotice
    "Editar Aviso de Revision": "Editar Aviso de Revision",
    "Odometro actual": "Odometro actual",
    "Odometro revisión": "Odometro revisión",
  },
};
